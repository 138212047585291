

.highlight_red_box {
    border: 0px dotted red;
}

.highlight_blue_box {
    border: 0px solid blue;
}

#spinner {
    background-color: aliceblue;
    margin: 300px auto !important;

}

.highlighter_svg {
    margin: auto !important;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.my-cssload-wrapper {
    /* left: 0; */
	/* right: 0; */
	/* bottom: 200; */
    /* top:300 !important; */
    border: 3px dotted red;
    margin: 80px auto !important;
    height: 550px;

}
#cssload-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
    overflow: hidden;
    border: 0px dotted red;

}

.cssstay-animatejs {
    border: 0px dotted green;

}


#cssstay-wrapper {
    position: absolute;
	left: 0;
    top: 0;
    right: 0;
	// right: 500px;
	bottom: 0;
    z-index: 16;
    /* margin: 100px auto; */
    // border: 2px dotted red;
    margin: 80px auto !important;
    height: 550px;
	overflow: hidden;
}
@import "./styles/loadspinner.scss";
@import "./node_modules/bulma/sass/utilities/_all.sass";
@import "./node_modules/bulma/sass/base/_all.sass";
@import "./node_modules/bulma/sass/layout/_all.sass";
@import "./node_modules/bulma/sass/elements/_all.sass";
@import "./node_modules/bulma/sass/components/_all.sass";
@import "./node_modules/bulma/sass/grid/_all.sass";