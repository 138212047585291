
 /* 
   # one load spinner
  */
  .spinner {
    width: 80px;
    height: 80px;
    margin: 100px auto;
    background-color: #333;
  
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  /* 
   # another load spinner
  */

  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  

  /*
   third load spinner (gauge)
  */
//   #cssload-wrapper {
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	z-index: 15;
// 	overflow: hidden;
// }

.cssload-loader {
	width: 146px;
	height: 146px;
	border: 1px rgb(0,0,0) solid;
	position: absolute;
	left: 50%;
	top: 30%;
	margin: -73px 0 0 -73px;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
}

.cssload-loader .cssload-loading {
	font-size: 10px;
	position: absolute;
	width: 100%;
	text-align: center;
	line-height: 14px;
	font-family: 'Century Gothic', sans-serif;
	font-style: italic;
	left: 0;
	top: 50%;
	margin-top: 19px;
	color: rgb(0,0,0);
	font-weight: bold;
	text-transform: uppercase;
		-o-text-transform: uppercase;
		-ms-text-transform: uppercase;
		-webkit-text-transform: uppercase;
		-moz-text-transform: uppercase;
}

.cssload-loader-circle-1 {
	width: 135px;
	height: 135px;
	left: 5px;
	top: 5px;
	border: 1px rgb(0,0,0) solid;
	position: absolute;
	border-right-color: transparent;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
	animation: spin 3.45s linear infinite;
		-o-animation: spin 3.45s linear infinite;
		-ms-animation: spin 3.45s linear infinite;
		-webkit-animation: spin 3.45s linear infinite;
		-moz-animation: spin 3.45s linear infinite;
}

.cssload-loader-circle-2 {
	width: 123px;
	height: 123px;
	left: 5px;
	top: 5px;
	border: 1px transparent solid;
	position: absolute;
	border-right-color: rgb(232,21,18);
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
	animation: spin 5.75s linear infinite;
		-o-animation: spin 5.75s linear infinite;
		-ms-animation: spin 5.75s linear infinite;
		-webkit-animation: spin 5.75s linear infinite;
		-moz-animation: spin 5.75s linear infinite;
}

.cssload-loader .cssload-line {
	width: 10px;
	height: 2px;
	background: rgb(0,0,0);
	position: absolute;
}

.cssload-loader .cssload-line:nth-child(1) {
	left: 16px;
	top: 50%;
	margin-top: -1px;
}

.cssload-loader .cssload-line:nth-child(2) {
	transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
	left: 32px;
	top: 32px;
}

.cssload-loader .cssload-line:nth-child(3) {
	top: 16px;
	left: 50%;
	width: 2px;
	height: 10px;
}

.cssload-loader .cssload-line:nth-child(4) {
	transform: rotate(135deg);
		-o-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
	right: 32px;
	top: 32px;
}

.cssload-loader .cssload-line:nth-child(5) {
	right: 16px;
	top: 50%;
	margin-top: -1px;
}

.cssload-loader .cssload-line:nth-child(6) {
	transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
	right: 32px;
	bottom: 32px;
	background: rgb(232,21,18);
}

.cssload-loader .cssload-subline {
	position: absolute;
	width: 3px;
	height: 2px;
	background: rgb(0,0,0);
}

.cssload-loader .cssload-subline:nth-child(7) {
	transform: rotate(22.5deg);
		-o-transform: rotate(22.5deg);
		-ms-transform: rotate(22.5deg);
		-webkit-transform: rotate(22.5deg);
		-moz-transform: rotate(22.5deg);
	left: 20px;
	top: 49px;
}

.cssload-loader .cssload-subline:nth-child(8) {
	transform: rotate(67.5deg);
		-o-transform: rotate(67.5deg);
		-ms-transform: rotate(67.5deg);
		-webkit-transform: rotate(67.5deg);
		-moz-transform: rotate(67.5deg);
	left: 49px;
	top: 20px;
}

.cssload-loader .cssload-subline:nth-child(9) {
	transform: rotate(112.5deg);
		-o-transform: rotate(112.5deg);
		-ms-transform: rotate(112.5deg);
		-webkit-transform: rotate(112.5deg);
		-moz-transform: rotate(112.5deg);
	right: 49px;
	top: 20px;
}

.cssload-loader .cssload-subline:nth-child(10) {
	transform: rotate(157.5deg);
		-o-transform: rotate(157.5deg);
		-ms-transform: rotate(157.5deg);
		-webkit-transform: rotate(157.5deg);
		-moz-transform: rotate(157.5deg);
	right: 20px;
	top: 49px;
}

.cssload-loader .cssload-subline:nth-child(11) {
	transform: rotate(22.5deg);
		-o-transform: rotate(22.5deg);
		-ms-transform: rotate(22.5deg);
		-webkit-transform: rotate(22.5deg);
		-moz-transform: rotate(22.5deg);
	right: 19px;
	bottom: 48px;
	background: rgb(232,21,18);
}

.cssload-loader .cssload-needle {
	width: 14px;
	height: 14px;
	border: 1px rgb(0,0,0) solid;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -8px 0 0 -8px;
	z-index: 1;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
	animation: pegIt 3.45s infinite ease-in-out;
		-o-animation: pegIt 3.45s infinite ease-in-out;
		-ms-animation: pegIt 3.45s infinite ease-in-out;
		-webkit-animation: pegIt 3.45s infinite ease-in-out;
		-moz-animation: pegIt 3.45s infinite ease-in-out;
}

.cssload-loader .cssload-needle:before {
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 3.5px 49px 3.5px 0;
	border-color: transparent rgb(232,21,18) transparent transparent;
	position: absolute;
	right: 50%;
	top: 50%;
	margin: -3.5px 0 0 0;
	border-radius: 0 50% 50% 0;
		-o-border-radius: 0 50% 50% 0;
		-ms-border-radius: 0 50% 50% 0;
		-webkit-border-radius: 0 50% 50% 0;
		-moz-border-radius: 0 50% 50% 0;
}





@keyframes pegIt {
	0% {
		transform: rotate(0deg);
	}
	16% {
		transform: rotate(75deg);
	}
	25% {
		transform: rotate(55deg);
	}
	30% {
		transform: rotate(90deg);
	}
	36% {
		transform: rotate(170deg);
	}
	42% {
		transform: rotate(150deg);
	}
	50% {
		transform: rotate(227deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@-o-keyframes pegIt {
	0% {
		-o-transform: rotate(0deg);
	}
	16% {
		-o-transform: rotate(75deg);
	}
	25% {
		-o-transform: rotate(55deg);
	}
	30% {
		-o-transform: rotate(90deg);
	}
	36% {
		-o-transform: rotate(170deg);
	}
	42% {
		transform: rotate(150deg);
	}
	50% {
		-o-transform: rotate(227deg);
	}
	100% {
		-o-transform: rotate(0deg);
	}
}

@-ms-keyframes pegIt {
	0% {
		-ms-transform: rotate(0deg);
	}
	16% {
		-ms-transform: rotate(75deg);
	}
	25% {
		-ms-transform: rotate(55deg);
	}
	30% {
		-ms-transform: rotate(90deg);
	}
	36% {
		-ms-transform: rotate(170deg);
	}
	42% {
		transform: rotate(150deg);
	}
	50% {
		-ms-transform: rotate(227deg);
	}
	100% {
		-ms-transform: rotate(0deg);
	}
}

@-webkit-keyframes pegIt {
	0% {
		-webkit-transform: rotate(0deg);
	}
	16% {
		-webkit-transform: rotate(75deg);
	}
	25% {
		-webkit-transform: rotate(55deg);
	}
	30% {
		-webkit-transform: rotate(90deg);
	}
	36% {
		-webkit-transform: rotate(170deg);
	}
	42% {
		transform: rotate(150deg);
	}
	50% {
		-webkit-transform: rotate(227deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}

@-moz-keyframes pegIt {
	0% {
		-moz-transform: rotate(0deg);
	}
	16% {
		-moz-transform: rotate(75deg);
	}
	25% {
		-moz-transform: rotate(55deg);
	}
	30% {
		-moz-transform: rotate(90deg);
	}
	36% {
		-moz-transform: rotate(170deg);
	}
	42% {
		transform: rotate(150deg);
	}
	50% {
		-moz-transform: rotate(227deg);
	}
	100% {
		-moz-transform: rotate(0deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
	}
}


/*
 fourth load spinner (2 squares)
*/


.cssload-spinner {
	position: absolute;
	width: 31px;
	height: 31px;
	left: 20%;
	margin-left: 815px;
	margin-top: -15px;
}
.cssload-spinner:after, .cssload-spinner:before {
	position: absolute;
	content: "";
	border: 2px solid rgb(161,161,161);
	width: 29px;
	height: 29px;
}
.cssload-spinner:after {
	animation: cssload-spinner1 2.88s linear infinite;
		-o-animation: cssload-spinner1 2.88s linear infinite;
		-ms-animation: cssload-spinner1 2.88s linear infinite;
		-webkit-animation: cssload-spinner1 2.88s linear infinite;
		-moz-animation: cssload-spinner1 2.88s linear infinite;
}
.cssload-spinner:before {
	width: 40px;
	height: 40px;
	margin-left: -5.5px;
	margin-top: -5.5px;
	animation: cssload-spinner2 2.88s linear infinite;
		-o-animation: cssload-spinner2 2.88s linear infinite;
		-ms-animation: cssload-spinner2 2.88s linear infinite;
		-webkit-animation: cssload-spinner2 2.88s linear infinite;
		-moz-animation: cssload-spinner2 2.88s linear infinite;
}



@keyframes cssload-spinner1 {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-o-keyframes cssload-spinner1 {
	from {
		-o-transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes cssload-spinner1 {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes cssload-spinner1 {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes cssload-spinner1 {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@keyframes cssload-spinner2 {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}

@-o-keyframes cssload-spinner2 {
	from {
		-o-transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(-360deg);
	}
}

@-ms-keyframes cssload-spinner2 {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(-360deg);
	}
}

@-webkit-keyframes cssload-spinner2 {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(-360deg);
	}
}

@-moz-keyframes cssload-spinner2 {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(-360deg);
	}
}

/*
 * the fifth load (3d)
*/
.cssload-wrap {
	width: 195px;
	height: 195px;
	margin: 97px auto;
	position: absolute;
    left:90%;
	// position: relative;
	perspective: 3900px;
		-o-perspective: 3900px;
		-ms-perspective: 3900px;
		-webkit-perspective: 3900px;
		-moz-perspective: 3900px;
	transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
}

.cssload-circle {
	transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
	box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	opacity: 0;
	width: 195px;
	height: 195px;
	border: 2px solid rgba(211, 229, 219, 0.81);
	border-radius: 146px;
	position: absolute;
	top: 0;
	left: 0;
	animation: cssload-spin 34.5s ease-in-out alternate infinite;
		-o-animation: cssload-spin 34.5s ease-in-out alternate infinite;
		-ms-animation: cssload-spin 34.5s ease-in-out alternate infinite;
		-webkit-animation: cssload-spin 34.5s ease-in-out alternate infinite;
		-moz-animation: cssload-spin 34.5s ease-in-out alternate infinite;
}
.cssload-circle:nth-of-type(1) {
	animation-delay: 575ms;
		-o-animation-delay: 575ms;
		-ms-animation-delay: 575ms;
		-webkit-animation-delay: 575ms;
		-moz-animation-delay: 575ms;
}
.cssload-circle:nth-of-type(2) {
	animation-delay: 1150ms;
		-o-animation-delay: 1150ms;
		-ms-animation-delay: 1150ms;
		-webkit-animation-delay: 1150ms;
		-moz-animation-delay: 1150ms;
}
.cssload-circle:nth-of-type(3) {
	animation-delay: 1725ms;
		-o-animation-delay: 1725ms;
		-ms-animation-delay: 1725ms;
		-webkit-animation-delay: 1725ms;
		-moz-animation-delay: 1725ms;
}
.cssload-circle:nth-of-type(4) {
	animation-delay: 2300ms;
		-o-animation-delay: 2300ms;
		-ms-animation-delay: 2300ms;
		-webkit-animation-delay: 2300ms;
		-moz-animation-delay: 2300ms;
}
.cssload-circle:nth-of-type(5) {
	animation-delay: 2875ms;
		-o-animation-delay: 2875ms;
		-ms-animation-delay: 2875ms;
		-webkit-animation-delay: 2875ms;
		-moz-animation-delay: 2875ms;
}



@keyframes cssload-spin {
	0% {
		transform: rotateY(0deg) rotateX(0deg);
		opacity: 1;
	}
	25% {
		transform: rotateY(180deg) rotateX(360deg);
	}
	50% {
		transform: rotateY(540deg) rotateX(540deg);
	}
	75% {
		transform: rotateY(720deg) rotateX(900deg);
	}
	100% {
		transform: rotateY(900deg) rotateX(1080deg);
		opacity: 1;
	}
}

@-o-keyframes cssload-spin {
	0% {
		-o-transform: rotateY(0deg) rotateX(0deg);
		opacity: 1;
	}
	25% {
		-o-transform: rotateY(180deg) rotateX(360deg);
	}
	50% {
		-o-transform: rotateY(540deg) rotateX(540deg);
	}
	75% {
		-o-transform: rotateY(720deg) rotateX(900deg);
	}
	100% {
		-o-transform: rotateY(900deg) rotateX(1080deg);
		opacity: 1;
	}
}

@-ms-keyframes cssload-spin {
	0% {
		-ms-transform: rotateY(0deg) rotateX(0deg);
		opacity: 1;
	}
	25% {
		-ms-transform: rotateY(180deg) rotateX(360deg);
	}
	50% {
		-ms-transform: rotateY(540deg) rotateX(540deg);
	}
	75% {
		-ms-transform: rotateY(720deg) rotateX(900deg);
	}
	100% {
		-ms-transform: rotateY(900deg) rotateX(1080deg);
		opacity: 1;
	}
}

@-webkit-keyframes cssload-spin {
	0% {
		-webkit-transform: rotateY(0deg) rotateX(0deg);
		opacity: 1;
	}
	25% {
		-webkit-transform: rotateY(180deg) rotateX(360deg);
	}
	50% {
		-webkit-transform: rotateY(540deg) rotateX(540deg);
	}
	75% {
		-webkit-transform: rotateY(720deg) rotateX(900deg);
	}
	100% {
		-webkit-transform: rotateY(900deg) rotateX(1080deg);
		opacity: 1;
	}
}

@-moz-keyframes cssload-spin {
	0% {
		-moz-transform: rotateY(0deg) rotateX(0deg);
		opacity: 1;
	}
	25% {
		-moz-transform: rotateY(180deg) rotateX(360deg);
	}
	50% {
		-moz-transform: rotateY(540deg) rotateX(540deg);
	}
	75% {
		-moz-transform: rotateY(720deg) rotateX(900deg);
	}
	100% {
		-moz-transform: rotateY(900deg) rotateX(1080deg);
		opacity: 1;
	}
}